<template>
  <v-container fluid class="pa-6">
    <div>
      <ManageGroups :config="config" />
    </div>
  </v-container>
</template>

<script>
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import { ManageGroups } from "ngt-group-manager-lib";

export default {
  name: "Groups",
  components: { ManageGroups },
  data() {
    return {
      selectedGroup: null,
      reload: false,
    };
  },
  computed: {
    config() {
      return {
        dataConnector: callBffAPI,
        applicationTitle: "Report Manager",
        forSuperAdmins: false,
        customerUri: this.$store.state.user.ownedBy._uri,
      };
    },
  },
  methods: {
    selectGroup(id) {
      this.selectedGroup = id;
    },
    reloadGroups() {
      this.reload = true;
    },
    groupsReloaded() {
      this.reload = false;
    },
  },
};
</script>
